import React from "react";
import "./Home.css";
import CommonButton from "../../components/UI/button/CommonButton/CommonButton";
import lamboPhoto from "../../assets/img/HomePage/lamboFigma.png";
import dillerImg3 from "../../assets/img/icons/xPelLogo.svg";
import dillerImg4 from "../../assets/img/HomePage/3MLogo.svg";
import dillerImg5 from "../../assets/img/HomePage/DenisonLogo.svg";
import servicesImg from "../../assets/img/HomePage/services-img.svg";
import whyUsPhoto from "../../assets/img/HomePage/whyUsPhoto.png";
import aboutUsPhoto from "../../assets/img/HomePage/aboutUsPhoto.png";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <main>
      <div className="wrapper-information wrapper-position">
        <p className="primary-text-information"> EXOTIC WINDOW TINT</p>
        <h6 className="secondary-text-information">
          LOCATED IN CHICAGO ILLINOIS. PREMIUM QUALITY CAR SELLING SHOP
        </h6>
        <div className="wrapper-button">
          <CommonButton
            color="greenText"
            background="blackBackground"
            size="largeSize"
            hover="darkHover"
            text="BOOK NOW"
            link="contact-us"
          />
        </div>
        <img className="lambo-photo" src={lamboPhoto} alt="" />
      </div>
      <div className="banner-diller">
        <div className="wrapper-diller-img">
          <img src={dillerImg3} alt="" className="diller-icon" />
          <img src={dillerImg4} alt="" className="diller-icon" />
          <img src={dillerImg5} alt="" className="diller-icon" />
        </div>
      </div>
      <div className="wrapper-services">
        {window.innerWidth < 600 ? (
          <div className="about-us">
            <div className="wrapper-img-about-us">
              <img className="img-about-us" src={aboutUsPhoto} alt="" />
            </div>
            <div className="wrapper-info-about-us">
              <div className="wrapper-main-text-about-us">
                <div className="sub-main-text-about-us">
                  <h5>
                    <p className="accent-color">/ WHO WE ARE</p>
                  </h5>
                </div>
                <div className="main-text-about-us">
                  <h3>
                    <p>Committed to Excellence</p>
                  </h3>
                </div>
              </div>
              <div className="wrapper-secondary-text-about-us">
                <h6>
                  <p className="secondary-text-about-us">
                    Since 2020, Driven Custom  has been providing
                    high-quality auto window tinting, paint protection film
                    (PPF), and ceramic coating services at competitive prices in
                    Buffalo Grove. Our dedication to craftsmanship and attention
                    to detail has earned us a solid reputation as a reliable
                    service provider. We use top-grade film to enhance the
                    appearance, functionality, comfort, and energy efficiency of
                    your car's glass. Our mission is to consistently meet and
                    exceed customer expectations with our products and precision
                    services. Choose Driven Custom  for your automotive
                    needs and experience the difference that our dedication to
                    quality and customer service can make. We look forward to
                    serving you and helping you maintain the beauty, comfort,
                    and longevity of your vehicle.
                  </p>
                </h6>
              </div>
              <div className="wrapper-button-about-us">
                <CommonButton
                  color="greenText"
                  background="blackBackground"
                  size="largeSize"
                  hover="darkHover"
                  text="BOOK NOW"
                  link="contact-us"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="wrapper-primary-text-services">
              <p className="primary-text-slash"></p>
              <p className="primary-text-services">SERVICES</p>
            </div>
            <div className="wrapper-img">
              <img src={servicesImg} alt="d" className="icon-Services" />
            </div>
            <div className="wrapper-btn-services">
              <CommonButton
                color="greenText"
                background="blackBackground"
                size="largeSize"
                hover="darkHover"
                text="CONTACT US"
                link="contact-us"
              />
            </div>
          </div>
        )}
      </div>
      <div className="wrapper-why-us">
        <div className="wrapper-info-home">
          <div className="wrapper-text">
            <div className="sub-main-text">
              <h6>
                <p>/ WHY CHOSE US</p>
              </h6>
            </div>
            <div className="main-text">
              <h2>
                <p>The Details Matter</p>
              </h2>
            </div>
            <div className="secondary-main-text">
              <h6>
                <p className="secondary-text-about-us">
                  We utilize top-quality materials and established techniques,
                  tailoring our services to match your specific needs. Our
                  unwavering dedication to our work and strong appreciation for
                  our clients are paramount to us.
                </p>
              </h6>
            </div>
          </div>
          <div className="wrapper-submit-instructions">
            <div className="wrapper-instruction">
              <div className="wrapper-photo-instruction">
                <div className="secondary-circle">
                  {" "}
                  <div className="primary-circle">
                    <h4>
                      <p className="accent-color">1</p>
                    </h4>
                  </div>
                </div>
                <div className="vertical-line"></div>
              </div>
              <div className="wrapper-info-instruction">
                <div className="main-text-info-instruction">
                  <h3>
                    <p>Submit Form</p>
                  </h3>
                </div>
                <div className="secondary-text-info-instruction">
                  <h6>
                    <p>
                      Share your specifications with us, and we will generate a
                      customized quote to meet your needs.
                    </p>
                  </h6>
                </div>
              </div>
            </div>
            <div className="wrapper-instruction">
              <div className="wrapper-photo-instruction">
                <div className="secondary-circle">
                  {" "}
                  <div className="primary-circle">
                    <h4>
                      <p className="accent-color">2</p>
                    </h4>
                  </div>
                </div>
                <div className="vertical-line"></div>
              </div>
              <div className="wrapper-info-instruction">
                <div className="main-text-info-instruction">
                  <h3>
                    <p>Book Appointment</p>
                  </h3>
                </div>
                <div className="secondary-text-info-instruction">
                  <h6>
                    <p>
                      We will arrange a convenient appointment for you to bring
                      your vehicle to our newly secured facility.
                    </p>
                  </h6>
                </div>
              </div>
            </div>
            <div className="wrapper-instruction">
              <div className="wrapper-photo-instruction">
                <div className="secondary-circle">
                  {" "}
                  <div className="primary-circle">
                    <h4>
                      <p className="accent-color">3</p>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="wrapper-info-instruction">
                <div className="main-text-info-instruction">
                  <h3>
                    <p>Protect Your Ride</p>
                  </h3>
                </div>
                <div className="secondary-text-info-instruction">
                  <h6>
                    <p>
                      Our skilled team is dedicated to getting it right the
                      first time, ensuring top-quality service.
                    </p>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper-btn-why-us">
            <CommonButton
              color="greenText"
              background="blackBackgroundWhiteBorder"
              size="largeSize"
              hover="darkHoverWhiteBorder"
              text="BOOK NOW"
              link="contact-us"
            />
          </div>
        </div>
        {window.innerWidth < 600 ? null : (
          <div className="wrapper-photo">
            <img
              className="photo-why-us"
              src={whyUsPhoto}
              alt="Wrap CHicago"
            ></img>
          </div>
        )}
      </div>
      {/*       <div className="banner-why-us">
        <div className="wrapper-text-why-us">
          <h2>
            <p className="primary-text-why-us">WHY US ?</p>
          </h2>
          <h1>
            {" "}
            <p className="primary-medumi-text-why-us">
              We have 5+ years expirience in this industry
            </p>
          </h1>
          <h3>
            {" "}
            <p className="secondary-text-why-us">
              Choose us for your window tinting needs. With over 5 years of
              experience, we deliver expertise, quality, and lasting
              satisfaction !
            </p>
          </h3>
        </div>
        <div className="wrapper-btn-banner-why-us">
          <CommonButton
            color="greenText"
            background="blackBackground"
            size="largeSize"
            hover="darkHover"
            text="CALL NOW"
            link="call-now"
          />
        </div>
      </div> */}
      <Footer />
    </main>
  );
};

export default Home;
