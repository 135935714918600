import React from 'react';

const LearnMore = () => {
    return (
        <div>
            <h1>THIS IS LEARN MORE PAGE</h1>
            dceecedc
        </div>
    );
}

export default LearnMore;
