import React from 'react';

const Services = () => {
    return (
        <div>
            <h1>THIS IS REVICES PAGE</h1>
        </div>
    );
}

export default Services;
