import React from "react";
import BlurData from "../../components/BlurData";
import Footer from "../../components/Footer";
import carWash from "../../assets/img/PaintProtectionFilmPackages/carWash.svg";
import carIcon from "../../assets/img/PaintProtectionFilmPackages/carIcon.svg";
import saveMoney from "../../assets/img/PaintProtectionFilmPackages/saveMoney.svg";
import BMW from "../../assets/img/icons/BMW.svg";
import Package from "../../components/Package";
import Banner from "../../components/Banner";
import Slider from "../../components/slider";
import InformationCardWrapper from "../../components/InformationCardWrapper";
import './CeramicCoating.css'

const CeramicCoating = () => {
  const selectButtonsInformation = [
    {
      id: 1,
      primaryText: "Gtechniq Serum Light",
      styleButton: "accentBorder",
      size: "smallSize",
      description: "Your detailed description for Chrome Delete",
      hover: "hoverBorderGreen",
      hoverText: "hoverText",
      textColor: "accentColor",
      isDisabled: true,
    },
    {
      id: 2,
      primaryText: "Gtechniq Crystal Serum Ultra",
      styleButton: "accentBackground",
      size: "smallSize",
      description: "Your detailed description for Gtechniq Crystal Serum Ultra",
      hover: "hoverBorderGreen",
      hoverText: "hoverText",
      textColor: "mainColor",
      isDisabled: false,
    },
    {
      id: 3,
      primaryText: "Gtechniq EXO",
      styleButton: "accentBackground",
      size: "smallSize",
      description: "Your detailed description for Gtechniq EXO",
      hover: "hoverBorderGreen",
      hoverText: "hoverText",
      textColor: "mainColor",
      isDisabled: false,
    },
  ];

  const contentInformation = [
    {
      id: 1,
      turnaround: "1-2 DAYS",
      content: [
        {
          id: 1,
          secondaryText:
            "Achieve the ideal balance of style and value with Gtechniq Serum Light. This cutting-edge formula delivers outstanding protection for your vehicle's paint, ensuring it remains in top condition. Serum Light is the smart choice for those who seek cost-effective yet highly effective automotive paint protection.",
        },
      ],
      primaryTextList: "Key Features:",
      primaryTextListStyle: "accentColor",
      secondaryTextStyle: "mainColorSecondaryText",
      secondaryTextListStyle: "mainSecondaryTextList",
      secondaryTextList: [
        {
          id: 1,
          content: "Application of ceramic coating to paint and bodywork in a single step.",
        },
        {
          id: 2,
          content: "Wheel faces receive a protective ceramic coating.",
        },
        {
          id: 3,
          content: "Complete ceramic coating for all automotive glass surfaces.",
        },
        {
          id: 4,
          content: "Exterior vinyl and plastic surfaces receive a protective ceramic coating.",
        },
        {
          id: 5,
          content:
            "Headlights and taillights receive a protective ceramic coating.",
        },
        {
          id: 6,
          content: "This includes coating the door jambs as well.",
        },
        {
          id: 7,
          content:
            "Included is a complimentary maintenance wash after the application.",
        },
        {
          id: 8,
          content:
            "Comes with a warranty – inquire with the detailer for specifics.",
        },
      ],
/*       packagePriceCard: [
        {
          id: 1,
          color: "mainColor",
          primaryText: "Cars",
          price: "$1,164",
          backgroundCard: "backgroundDark",
        },
      ], */
    },
    {
      id: 2,
      turnaround: " 1-2 DAYS",
      content: [
        {
          id: 1,
          secondaryText:
            "Achieve ultimate automotive paint protection with Gtechniq Crystal Serum Ultra. Its unmatched durability and lasting shine make it the top choice for a pristine, glossy finish.",
        },
      ],
      primaryTextList: "Key Features:",
      primaryTextListStyle: "accentColor",
      secondaryTextStyle: "mainColorSecondaryText",
      secondaryTextListStyle: "mainSecondaryTextList",
      secondaryTextList: [
        {
          id: 1,
          content: "Includes everything from our Gtechniq Serum Light package.",
        },
        {
          id: 2,
          content: "Full wheel, including the wheel barrels, receives a protective ceramic coating.",
        }
      ],
/*       packagePriceCard: [
        {
          id: 1,
          color: "mainColor",
          primaryText: "Cars",
          price: "$300",
          backgroundCard: "backgroundDark",
        },
      ], */
    },
    {
      id: 3,
      turnaround: "1 DAY",
      content: [
        {
          id: 1,
          secondaryText:
            "Enhance your vehicle's protection and shine with Gtechniq EXO. This cutting-edge hybrid coating offers excellent durability and water-repellent properties. With EXO, your car's surface will stay cleaner for longer and be better shielded against the elements. Elevate your vehicle's appearance and protection with Gtechniq EXO. NOTE: This covers your vehicle’s paintwork only.",
        },
      ],
      primaryTextList: "Key Features:",
      primaryTextListStyle: "accentColor",
      secondaryTextStyle: "mainColorSecondaryText",
      secondaryTextListStyle: "mainSecondaryTextList",
      secondaryTextList: [
        {
          id: 1,
          content: "One application for ceramic protection on both paint and bodywork.",
        },
      ]
/*       packagePriceCard: [
        {
          id: 1,
          color: "mainColor",
          primaryText: "Starting at",
          price: "$3,500",
          backgroundCard: "backgroundDark",
        },
      ], */
    },
  ];

  const informationCard = [
    {
        id: 1,
        imgSrc: carWash,
        primaryText: "Full Resistance Against Contamination",
        secondaryText: "A durable, hydrophobic surface that encourages water droplets to bead up also effectively safeguards your vehicle's original finish against dirt, dust, and other solid contaminants. Our Gtechniq Ceramic Coating forms an impenetrable barrier, ensuring comprehensive protection regardless of the package you choose."
    },
    {
        id: 2,
        imgSrc: carWash,
        primaryText: "Mandatory Certified Installation",
        secondaryText: "Knowing that only certified detailers can work with the ceramic coating products we offer here at Chicago Auto Pros should bring you peace of mind. Your automobile will experience true, professional paint protection services at our Chicago, Illinois shop, and specific to our Gtechniq Ceramic Coating packages, will have a unique surface gloss and level of resistance to fading and stains unattainable by any cheap imitation alternatives."
    },
    {
        id: 3,
        imgSrc: carIcon,
        primaryText: "Value Enhancement Beyond Compare",
        secondaryText: "An investment in a vehicle ceramic coating is a long-term investment in the quality and overall value of your automobile. Paint protection efforts like that brought on by a Gtechniq Ceramic Coating improve your vehicle’s value by both defending the original paintwork but likewise, by illustrating to any future owner or appraiser that you have paid meticulous attention to its preservation."
    },
    {
        id: 4,
        imgSrc: saveMoney,
        primaryText: "Multiple Protective Layers for Enhanced Surface Hardness",
        secondaryText: "With our Gtechniq Ceramic Coating packages, you can apply a multi-layer base ceramic coating to your vehicle, complemented by a surface enhancement coating like our Gtechniq EXO package. This dual approach enhances your automobile's protection, shine, and overall value."
    }
]

  const titleInformationBanner = "Shiny Surfaces and Resistance to Environmental Contaminants";
  const titlePackage = "Driven Customs - Vinyl Color Vehicle Wrap Packages";

  return (
    <div>
      <BlurData
        primaryText="Team Specializing in Ceramic Coating Installation"
        img={BMW}
      />
      <Package
        title={titlePackage}
        selectButtonsInformation={selectButtonsInformation}
        contentInformation={contentInformation}
        imgBg={BMW}
      />
      <div className="wrapper-banner">
        <Banner
          content="Achieving a spotless surface has never been easier! At Driven Customs, our priority when applying Gtechniq Ceramic Coating packages is to enhance both the aesthetics and functionality of your vehicle's exterior. We aim to make it highly resistant to chemical and environmental contaminants while ensuring it maintains a dazzling appearance long after the coating is applied.

          What sets us apart at Driven Customs is our team of professionally certified ceramic coating installers who exclusively work with Gtechniq Ceramic Coatings. These coatings aren't available for purchase by the average individual and can only be acquired by licensed and certified installers like us.
          
          Whether you're seeking basic paint protection or long-lasting brilliance, Driven Customs has you covered. Schedule your ceramic coating installation today, reach out to our detailing team for more details, or request an estimate for your installation service today."
          accentText="Discover "
          textBorderLine=" Various Styles at Driven Customs - Matte, Gloss, and More!"
        />
      </div>

      <section className="wrapper-informatio-cards">
                {window.innerWidth < 600 ? (
                    <Slider packages={informationCard} type="information-card" titleInformationBanner={titleInformationBanner}/>
                ) : (
                    <InformationCardWrapper informationCard={informationCard} title={titleInformationBanner} />
                )}
            </section>

      <Footer />
    </div>
  );
};

export default CeramicCoating;
