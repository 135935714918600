import React from "react";
import Footer from "../../components/Footer";
import BlurData from "../../components/BlurData";
import fleet from "../../assets/img/FleetPhoto.svg";
import "./FleetDetaling.css";

const FleetDetaling = () => {
  return (
    <div>
      <BlurData
        primaryText="Comprehensive Interior Vehicle Detailing Services in Chicago, IL by Fleet Detailing."
        secondaryText="Safeguard the Intricacies of Your Vehicle's Interior with Fleet Detailing."
        img={fleet}
        wrapperContetImg="wrapperContetImg"
      />

      <div className="wrapper-info">
        <h1>
          <p className="accent-color">FOR MORE INFO PLREASE CALL US</p>
        </h1>
        <a href="tel:+1-708-830-3694" className="click-here">
          <h3>
            <p className="">CLICK HERE TO CALL</p>
          </h3>
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default FleetDetaling;
