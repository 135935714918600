import React from "react";
import SelectCard from "./SelectCard";
import leftArrow from "../assets/img/icons/left-arrow.svg";
import rightArrow from "../assets/img/icons/right-arrow.svg";
import "../css/slider.css";
import InformationCardWrapper from "./InformationCardWrapper";
import { motion, AnimatePresence } from "framer-motion";

const Slider = ({ packages, type, titleInformationBanner }) => {
  const [slide, setSlide] = React.useState(1);

  const nextSlide = () => {
    setSlide((prev) => (prev === packages.length ? 1 : prev + 1));
  };

  const previusSlide = () => {
    setSlide((prev) => (prev === 1 ? packages.length : prev - 1));
  };

  return (
    <div className="wrapper-slider">
      <button className="btn-arrow btn-arrow-left" onClick={previusSlide}>
        <img src={leftArrow} alt="" />
      </button>
      <AnimatePresence  custom={slide} mode="wait">
        <motion.div
          key={slide}
          initial={{ opacity: 0 }}
          animate={{  opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          {type === "select-card" ? (
            packages.map((item) => (
              <SelectCard
                key={item.id}
                id={item.id}
                styleCards={item.styleCards}
                sizeCard={item.sizeCard}
                paddingCard={item.paddingCard}
                primaryText={item.primaryText}
                colorPrimaryText={item.colorPrimaryText}
                bannerBg={item.bannerBg}
                bannerText={item.bannerText}
                secondaryText={item.secondaryText}
                secondaryTextStyle={item.secondaryTextStyle}
                background={item.background}
                primaryTextList={item.primaryTextList}
                primaryTextListStyle={item.primaryTextListStyle}
                secondaryTextList={item.secondaryTextList}
                secondaryTextListStyle={item.secondaryTextListStyle}
                button={item.button}
                buttonName={item.buttonName}
                slide={slide}
                onButtonClick={item.handleButtonClick}
                img={item.img}
              />
            ))
          ) : (
            <InformationCardWrapper
              informationCard={packages}
              title={titleInformationBanner}
              slide={slide}
            />
          )}
        </motion.div>
      </AnimatePresence>

      <button className="btn-arrow btn-arrow-right" onClick={nextSlide}>
        <img src={rightArrow} alt="" />
      </button>

      <div className="wrapper-dots">
        {packages.map((item) => (
          <div
            key={item.id}
            className={`dot  ${item.id === slide ? "dot-selected" : ""}`}
            onClick={() => setSlide(item.id)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
