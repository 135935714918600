import React from "react";
import InformationCardBanner from "./InformationCardBanner";
import style from "../css/InformationCardWrapper.module.css";

const InformationCardWrapper = ({informationCard, slide, title}) => {
  console.log(title);
  return (
    <div className={style.wrapperBannerCardInformation}>
      <InformationCardBanner informationCard={informationCard} slide={slide} title={title}/>
    </div>
  );
};

export default InformationCardWrapper;
