import React from "react";
import InformationCard from "./InformationCard";
import style from "../css/InformationCardBanner.module.css"

const InformationCardBanner = ({informationCard, title, slide}) => {
  return (
    <div >
      <div className={style.wrapperPrimaryTextStyledGreen}>
        <h1 className={style.primaryTextStyledGreen}>
          <p>{title}</p>
        </h1>
      </div>
      <div className={style.wrapperInformationCards}>
        {informationCard.map((item) => (
          <InformationCard
            key={item.id}
            id={item.id}
            img={item.imgSrc}
            primaryText={item.primaryText}
            secondaryText={item.secondaryText}
            slide={slide}
          />
        ))}
      </div>
    </div>
  );
};

export default InformationCardBanner;
