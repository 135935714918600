import React from "react";
import leftArrow from "../assets/img/icons/left-arrow.svg";
import rightArrow from "../assets/img/icons/right-arrow.svg";
import "../css/SliderGallary.css";
import { motion, AnimatePresence } from "framer-motion";

const Slider = ({ packages }) => {
  const [slide, setSlide] = React.useState(0);

  const nextSlide = () => {
    setSlide((prev) => (prev === packages.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setSlide((prev) => (prev === 0 ? packages.length - 1 : prev - 1));
  };

  return (
    <div className="wrapper-slider-gallery">
      <button
        className="btn-arrow-gallery btn-arrow-left-gallery"
        onClick={() => setSlide((prev) => (prev === 0 ? packages.length - 1 : prev - 1))}
      >
        <img src={leftArrow} alt="" />
      </button>

      <AnimatePresence initial={false} custom={slide}>
        <motion.div
          key={slide}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            objectFit: "cover",
            maxHeight: "20rem",
          }}
        >
          <img
            src={packages[slide].imgSrc}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          />
        </motion.div>
      </AnimatePresence>

      <button
        className="btn-arrow-gallery btn-arrow-right-gallery"
        onClick={() => setSlide((prev) => (prev === packages.length - 1 ? 0 : prev + 1))}
      >
        <img src={rightArrow} alt="" />
      </button>

      <div className="wrapper-dots-gallery">
        {packages.map((item, index) => (
          <div
            key={index}
            className={`dot ${index === slide ? "dot-selected" : ""}`}
            onClick={() => setSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
